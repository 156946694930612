<template>
  <div class="page">
    <LandingHeader/>
      <section id="contacts" class="contacts" style="flex:1 0 auto;">
        <div class="container">
          <div class="contacts__inner row">
            <div class="contacts__decor">
              <div class="contacts__img">
                <img src="@/assets/landing/img/007_formatted.jpg" alt="" />
              </div>
              <div class="contacts__hint">
                <p
                    class="contacts__text"
                    v-html="$t('landing.contacts.text')"
                ></p>
              </div>
            </div>

            <div class="contacts__info">
              <h2 class="title title_contacts">
                {{ $t("landing.contacts.title") }}
              </h2>
              <p
                  class="contacts__descr"
                  v-html="$t('landing.contacts.descr')"
              ></p>
    <!--          <div class="contacts__tel">-->
    <!--            <div class="tel__text">{{ $t("landing.contacts.label") }}</div>-->
    <!--            <a href="tel:+385919721866" class="tel__link">+385 91 972 1866</a>-->
    <!--          </div>-->
              <div class="mails__tel">
                <div class="tel__text">Contact email:</div>
                <a href="mailto:guest@tourist.com" class="tel__link">guest@tourist.com</a>
              </div>
              <a
                  href="/registration"
                  class="btn popup__open"
                  v-if="!$store.state.token"
              >{{ $t("landing.contacts.btn") }}</a
              >
            </div>
          </div>
        </div>
      </section>
    <LandingFooter/>
  </div>
</template>

<script>


export default {
  name: "LandingContact",
  components: {
    LandingHeader:() => import("@/components/Landing/LandingHeader"),
    LandingFooter:() => import("@/components/Landing/LandingFooter")
  },
  mounted() {
    document.title = 'Contacts';
  }
}
</script>

<style lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 40px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

span,
time,
label {
  display: inline-block;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.5;
}

button {
  cursor: pointer;
}
@import "@/scss/landing/style.scss";
</style>
